<template>
  <div class="index">
    <!-- 个案辅导 -->
    <div class="searchBtn">
      <el-select v-model="searchInfo.sex" placeholder="请选择性别" size="small">
        <el-option label="全部" :value="4">
        </el-option>
        <el-option label="男" :value="1">
        </el-option>
        <el-option label="女" :value="2">
        </el-option>
        <el-option label="未填写" :value="3">
        </el-option>
      </el-select>
      <el-cascader :options="OrganizationsList" v-model="searchInfo.deptId" size="small" placeholder="请选择组织机构"
        :show-all-levels="false" :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }">
      </el-cascader>
      <!-- <el-cascader :options="RolesList" placeholder="请选择角色" v-model="searchInfo.roleId" size="small"
        :props="{ value: 'id', label: 'name', emitPath: false }" :show-all-levels="false"></el-cascader> -->
      <el-select v-model="searchInfo.consultId" placeholder="请选择咨询师姓名" size="small" @focus="selectConsult" clearable>
        <el-option v-for="item in TeacherData" :key="item.id" :label="item.username" :value="item.id">
        </el-option>
      </el-select>
      <!-- <div style="position:relative;">
        <el-input placeholder="请选择咨询师姓名" v-model="searchInfo.consultId"  size="small" @focus="selectConsult"></el-input>
        <i v-if="searchInfo.name" @click="clearConsult" class="el-icon-circle-close" style="position:absolute; right: 35px; line-height: 32px; color: #DCDFE6;"></i>
      </div> -->

      <el-select v-model="searchInfo.level" placeholder="请选择处理级别" size="small" clearable>
        <el-option label="一级" :value="1">
        </el-option>
        <el-option label="二级" :value="2">
        </el-option>
        <el-option label="三级" :value="3">
        </el-option>
      </el-select>
      <el-select v-model="searchInfo.status" placeholder="请选择处理状态" size="small" clearable>
        <el-option label="未开始" :value="1">
        </el-option>
        <el-option label="进行中" :value="2">
        </el-option>
        <el-option label="已结束" :value="3">
        </el-option>
      </el-select>
      <div class="bottom">
        <el-input placeholder="开始年龄" v-model="searchInfo.startAge" type="number" size="small" clearable
          oninput="value=value.replace(/[^0-9.]/g,'')" style="margin-right:10px"></el-input>
        <span>至</span>
        <el-input placeholder="结束年龄" v-model="searchInfo.endAge" type="number" size="small" clearable
          oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
        <el-date-picker size="small" v-model="searchInfo.startTime" type="date" placeholder="开始时间"
          value-format="yyyy-MM-dd 00:00:00" style="margin-right:10px" format="yyyy-MM-dd">
        </el-date-picker>
        <span>至</span>
        <el-date-picker size="small" v-model="searchInfo.endTime" type="date" placeholder="结束时间" format="yyyy-MM-dd"
          value-format="yyyy-MM-dd 23:59:59">
        </el-date-picker>
        <el-input placeholder="请输入" v-model="searchInfo.name" size="small" clearable></el-input>
        <el-button type="primary" size="small" @click="search">搜索</el-button>
      </div>
    </div>
    <div class="tableContent">
      <div class="tableBtn">
        <el-button type="primary" size="small" @click="addCoach">新增</el-button>
        <el-button type="primary" size="small" @click="downloadCoachs">导出数据</el-button>
        <el-button type="primary" size="small" @click="downloadReport">导出报告</el-button>
        <el-button type="primary" size="small" @click="deleteCoachList">删除</el-button>
      </div>
      <template>
        <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="userId" label="用户ID" width="100" fixed></el-table-column>
          <el-table-column prop="username" label="登录名" width="100" show-overflow-tooltip fixed></el-table-column>
          <el-table-column prop="nickname" label="姓名" width="100" show-overflow-tooltip fixed></el-table-column>
          <el-table-column label="性别" width="80" fixed>
            <template slot-scope="scope">
              <span v-if="scope.row.sex === 1">男</span>
              <span v-else-if="scope.row.sex === 2">女</span>
              <span v-else-if="scope.row.sex === 3">未填写</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="province" label="UID">
          </el-table-column> -->
          <el-table-column prop="age" label="年龄" width="80" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="deptName" label="组织机构" width="180" show-overflow-tooltip>
          </el-table-column>
          <!-- <el-table-column prop="roleName" label="用户角色" width="180" show-overflow-tooltip>
          </el-table-column> -->
          <el-table-column prop="consultName" label="咨询师姓名" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="consultDate" label="咨询日期" width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="roleName" label="咨询时间" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.startTime">
                {{ scope.row.startTime }}-{{ scope.row.endTime }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="处理级别" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.level === 1">一级</span>
              <span v-else-if="scope.row.level === 2">二级</span>
              <span v-else-if="scope.row.level === 3">三级</span>
            </template>
          </el-table-column>
          <el-table-column label="处理状态" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.status === 1">未开始</span>
              <span v-else-if="scope.row.status === 2">进行中</span>
              <span v-else-if="scope.row.status === 3">已结束</span>
            </template>
          </el-table-column>
          <el-table-column prop="source" label="咨询来源" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="createName" label="创建人" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="170">
          </el-table-column>

          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <!-- <el-button type="text" size="small" @click="openDetail(scope.row.id)">查看</el-button> -->
              <el-button v-if="scope.row.source === '创建人新增'" type="text" size="small"
                @click="toReport(1, scope.row.id)">咨询报告</el-button>
              <el-button v-if="scope.row.source === '危机干预'" type="text" size="small" @click="toReport(2, scope.row.id)">
                干预报告</el-button>
              <!-- <el-button type="text" size="small" >咨询报告</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
        layout="total, prev, pager, next, jumper" :total="paging.total">
      </el-pagination>
    </div>
    <selectConsultantVue ref="select" @getConsult="getConsult"></selectConsultantVue>
    <AddCoachVue ref="add" @getCoachLists="getCoachLists"></AddCoachVue>
  </div>
</template>

<script>
import { getOrganizationList } from '@/api/system/organization';
import { getRoleList } from '@/api/system/role'
import selectConsultantVue from './selectConsultant.vue';
import AddCoachVue from './AddCoach.vue';
import { getCoachList, deleteCoach, downloadCoach, downloadCoachReport } from '@/api/healthRecords/coach';
import { getTeachersList } from '@/api/dangerWarning/crisisIntervention'
export default {
  components: { selectConsultantVue, AddCoachVue },
  data() {
    return {
      searchInfo: {
        deptId: null,
        consultId: null,
        endAge: null,
        endTime: null,
        level: null,
        name: null,
        startAge: null,
        sex: null,
        startTime: null,
        status: null,

      }, // 搜索信息
      paging: {
        p: 1,
        ps: 20,
        total: 0
      }, // 分页信息
      OrganizationsList: [], // 组织机构列表
      RolesList: [], // 角色列表
      tableData: [], // 表格数据
      TeacherData: [], // 咨询师
      deleteList: [], // 需要删除的数组
    }
  },
  methods: {
    // 获取组织机构列表
    async getOrganizationsList() {
      const res = await getOrganizationList()
      // console.log('组织机构');
      console.log(res);
      if (res.code === 200) {
        this.OrganizationsList = res.data;
        this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList]
        // this.deleteNullChildren(this.OrganizationsList)
      }
    },
    // 获取角色列表
    async getRolesList() {
      const res = await getRoleList();
      console.log(res);
      if (res.code === 200) {
        this.RolesList = res.data;
        this.RolesList = [{ id: -1, name: '全部' }, ...this.RolesList]
      }
    },
    // 获取个案辅导列表
    async getCoachLists() {
      let info = { ...this.searchInfo };
      if (info.sex === 4) {
        info.sex = null
      }
      if (info.deptId === -1) {
        info.deptId = null
      }
      const res = await getCoachList(this.paging, JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.tableData = res.data.records;
        this.paging.total = res.data.total;
      }
    },
    // 删除个案辅导
    async deleteCoachList() {
      if (this.deleteList.length > 0) {
        this.$confirm('确认删除该个案辅导?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let arr = [];
          this.deleteList.forEach(item => {
            arr.push(item.id)
          })
          const res = await deleteCoach(arr);
          console.log(res);
          if (res.code === 200) {
            this.$message({
              showClose: true,
              type: 'success',
              message: '删除成功!'
            });
            this.getCoachLists();
          } else {
            this.$message({
              showClose: true,
              type: 'error',
              message: '删除失败' + res.msg
            });
          }

        }).catch(() => {
          this.$message({
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });

      } else {
        this.$message({
          showClose: true,
          message: '请选择需要删除的个案辅导!'
        });
      }


    },
    // 点击新增
    addCoach() {
      this.$refs.add.open();
    },
    // 点击搜索
    search() {
      this.paging.p = 1;
      this.getCoachLists();
    },
    // 清空选择的咨询师
    clearConsult() {
      // console.log(11)
      this.searchInfo.name = null;
      this.$forceUpdate();
    },
    // 用户点击选择的选项
    handleSelectionChange(val) {
      console.log(val);
      this.deleteList = val;
    },
    // 点击分页
    handleCurrentChange(val) {
      this.paging.p = val;
      this.getCoachLists();
    },
    // 点击查看报告
    toReport(val, val2) {
      if (val === 1) {
        this.$router.push({
          path: '/home/consult',
          query: {
            id: val2
          }
        })
      } else if (val === 2) {
        this.$router.push({
          path: '/home/coachArchives',
          query: {
            id: val2
          }
        })
      }
    },
    // 选择咨询师 打开选择咨询师弹框
    selectConsult() {
      this.$refs.select.open();
    },
    // 获取用户选择的咨询师
    getConsult(val) {
      console.log(val);
      this.searchInfo.consultId = val.id;
      this.$forceUpdate();
    },
    // 获取咨询师列表
    async getTeacherList() {
      const res = await getTeachersList();
      console.log(res);
      if (res.code === 200) {
        this.TeacherData = res.data;
      }
    },
    // 导出数据
    async downloadCoachs() {
      if (this.deleteList.length > 0) {
        let params = []
        this.deleteList.forEach(item => {
          params.push({
            id: item.id,
            source: item.source
          })
        })
        const res = await downloadCoach(JSON.stringify(params));
        const blob = new Blob([res]);  // 把得到的结果用流对象转一下
        var a = document.createElement("a"); //创建一个<a></a>标签
        a.href = URL.createObjectURL(blob); // 将流文件写入a标签的href属性值
        a.download = "个人辅导数据.xlsx"; //设置文件名
        a.style.display = "none";  // 障眼法藏起来a标签
        document.body.appendChild(a); // 将a标签追加到文档对象中
        a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
        a.remove();
      } else {
        this.$message({
          showClose: true,
          message: '请选择需要导出的数据！'
        });
      }

    },
    // 导出报告
    async downloadReport() {
      if (this.deleteList.length > 0) {
        let params = []
        this.deleteList.forEach(item => {
          params.push({
            id: item.id,
            source: item.source
          })
        })
        const res = await downloadCoachReport(JSON.stringify(params));
        console.log(res);
        var reader = new FileReader()
        reader.readAsDataURL(res)
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement('a')
          a.download = '个案辅导报告.zip' // 设置下载的文件名称
          a.href = e.target.result
          // 兼容触发click
          var evt = document.createEvent('MouseEvents')
          evt.initEvent('click', true, true)
          a.dispatchEvent(evt);
        }
          // const blob = new Blob([res]);  // 把得到的结果用流对象转一下
          // var a = document.createElement("a"); //创建一个<a></a>标签
          // a.href = URL.createObjectURL(blob); // 将流文件写入a标签的href属性值
          // a.download = "个人辅导数据.xlsx"; //设置文件名
          // a.style.display = "none";  // 障眼法藏起来a标签
          // document.body.appendChild(a); // 将a标签追加到文档对象中
          // a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
          // a.remove();
        } else {
        this.$message({
          showClose: true,
          message: '请选择需要导出的数据！'
        });
      }
    }
  },
  created() {
    this.getCoachLists();
    this.getOrganizationsList();
    this.getTeacherList();
  }
}
</script>

<style lang="scss" scoped>
.index {
  padding: 20px;

  .searchBtn {
    ::v-deep.el-date-editor {
      width: 150px !important;
    }

    span {
      display: inline-block;
      margin-right: 10px;
      line-height: 32px;
    }

    .bottom {
      margin-top: 20px;
    }
  }

  .tableContent {
    .tableBtn {
      margin: 20px 0px;
    }
  }
}
</style>