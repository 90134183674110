<template>
    <div>
        <!-- 选择咨询师 -->
        <el-dialog title="请选择咨询师" :visible.sync="dialogVisible" width="50%">
            <el-table :data="consultant" border style="width: 100%" size="small" height="300px">
                <el-table-column width="50">
                    <template slot-scope="scope">
                        <el-radio v-model="consultantId" :label="scope.row.id">&nbsp;</el-radio>
                    </template>
                </el-table-column>
                <el-table-column prop="nickname" label="姓名" >
                </el-table-column>
                <el-table-column prop="username" label="登录名" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column show-overflow-tooltip label="角色" prop="roleName" >
                </el-table-column> -->
                <el-table-column label="组织机构" prop="departmentName"  show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getTeachersList } from '@/api/dangerWarning/crisisIntervention'
export default {
    data() {
        return {
            consultant: [],
            dialogVisible: false, //控制模块框是否显示
            consultantId: null, // 咨询师id
        }
    },
    methods: {
        // 获取咨询师列表
        async getTeacherList() {
            const res = await getTeachersList();
            console.log(res);
            if (res.code === 200) {
                this.consultant = res.data;
            }
        },
        // 打开当前模态框
        open() {
            // this.consultantId = null;
            this.dialogVisible = true;
            this.getTeacherList();
        },
        submit() {
            console.log(this.consultantId);

            if (this.consultantId) {
                this.consultant.forEach(item => {
                    if (item.id === this.consultantId) {
                        this.$emit('getConsult', item);
                        this.dialogVisible = false;
                    }
                });
            } else {
                this.$message({
                    showClose: true,
                    message: '请选择咨询师'
                });
            }
        }
    }
}
</script>

<style>

</style>