import request from '@/utils/request'
// 获取档案管理列表
export function getFileList(paging, data) {
    return request({
      url: `/archives/page/${paging.p}/${paging.ps}`,
      method: 'post',
      data
    });
}
// 获取档案管理用户信息
export function getUserInfo(id, params) {
  return request({
    url: `/archives/user/${id}`,
    method: 'get',
    params
  });
}
// 获取心理健康记录表列表
export function getRecordList( data) {
  return request({
    url: `/archives/record`,
    method: 'post',
    data
  });
}
