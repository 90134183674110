<template>
    <div>
        <!-- 新增咨询用户 -->
        <el-dialog title="请选择用户" :visible.sync="dialogVisible" width="50%">
            <el-table :data="tableData" border style="width: 100%" size="small" height="300px">
                <el-table-column width="50">
                    <template slot-scope="scope">
                        <el-radio v-model="userId" :label="scope.row.id">&nbsp;</el-radio>
                    </template>
                </el-table-column>
                <el-table-column prop="nickname" label="姓名">
                </el-table-column>
                <el-table-column prop="username" label="登录名" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column show-overflow-tooltip label="角色" prop="roleName" width="200">
                </el-table-column> -->
                <el-table-column label="部门" prop="departmentName" show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getFileList } from '@/api/healthRecords/filemanagement'
import { addCoach } from '@/api/healthRecords/coach'
export default {
    data() {
        return {
            tableData: [],
            dialogVisible: false, //控制模块框是否显示
            userId: null, // 咨询师id
        }
    },
    methods: {
        // 获取可选择用户列表
        async getManagementList() {
            const res = await getFileList({ p: 1, ps: 100000 }, {});
            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data.records;
            }
        },
        // 打开当前模态框
        open() {
            this.dialogVisible = true;
            this.getManagementList();
        },
        // 确认
        async submit() {
            console.log(this.userId);

            if (this.userId) {
                const res = await addCoach(this.userId);
                console.log(res);
                if (res.code === 200) {
                    this.$message({
                        showClose: true,
                        type: 'success',
                        message: '新增成功！'
                    });
                    setTimeout(() => {
                        this.dialogVisible = false;
                        this.$emit('getCoachLists');
                    }, 2000);
                }

            } else {
                this.$message({
                    showClose: true,
                    message: '请选择用户'
                });
            }
        }
    }
}
</script>

<style>

</style>